<template>
    <div>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-subheader>{{ alle ? 'Med alle:' : 'Med valgte:' }}</v-subheader>
                <v-list-item-group color="primary">
                    <v-list-item v-on:click="exportExcelList">
                        <v-list-item-icon>
                            <v-icon>mdi-file-excel</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Eksporter liste</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="exportExcelContactInfo">
                        <v-list-item-icon>
                            <v-icon>mdi-file-excel</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Eksporter utvidet informasjon</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'GreifActions',
    props: {
        value: {
            default: function () {
                return [];
            },
            type: Array,
        },
        headers: {
            type: Array,
            default: null,
        },
        alle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fields: {
                lopenr: {
                    label: 'Nr.',
                    width: 5,
                },
                tittel: {
                    label: 'Gruppe',
                    width: 40,
                },
                motetidspunkt: {
                    label: 'Møtetidspunkt',
                    width: 20,
                },
                ledere: {
                    label: 'Gruppeledere',
                    width: 60,
                },
                leder: {
                    label: 'Gruppeleder',
                    width: 40,
                },
                deltakere: {
                    label: 'Medlemmer',
                    width: 100,
                },
                deltaker: {
                    label: 'Medlemmer',
                    width: 40,
                },

                antallGrupper: {
                    label: 'Antall grupper',
                        width: 20,
                },
                antallPlasser: {
                    label: 'Antall plasser',
                    width: 20,
                },
                antallDeltakere: {
                    label: 'Antall medlemmer',
                    width: 20,
                },
                antallTilordnet: {
                    label: 'Antall tilordnet',
                    width: 20,
                },
                antallLedigePlasser: {
                    label: 'Ledige plasser',
                    width: 20,
                },
                antallVenteliste: {
                    label: 'På venteliste',
                        width: 20,
                },
            },

            contactFields: ['lopenr', 'tittel', 'motetidspunkt', 'leder', 'deltaker'],
        };
    },
    filters: {},
    methods: {
        ...mapActions(['snackbar']),
        ...mapActions('api', ['exportToExcel']),

        /**
         * exportExcelContactInfo
         */
        exportExcelList: async function () {
            const table = [];
            const header = [];
            const wscols = [];

            for (let field of this.headers) {
                field = field.value;
                header.push(this.fields[field].label);
                wscols.push({ width: this.fields[field].width });
            }

            table.push(header);

            for (const group of this.value) {
                const row = [];
                for (let field of this.headers) {
                    switch (field.value) {
                        case 'ledere': {
                            const ledere = group.ledere
                                ? group.ledere
                                    .map((leder) => {
                                        return leder && leder.person && !leder.fjernet ? leder.person.fornavn + ' ' + leder.person.etternavn : null;
                                    })
                                    .filter((leder) => leder != null)
                                    .join(', ')
                                : [];

                            row.push(ledere);
                            break;
                        }

                        case 'deltakere': {
                            const deltakere = group.deltakere
                                ? group.deltakere
                                      .map((deltaker) => {
                                          return deltaker && deltaker.person && !deltaker.fjernet
                                              ? deltaker.person.fornavn + ' ' + deltaker.person.etternavn
                                              : null;
                                      })
                                      .filter((deltaker) => deltaker != null)
                                      .join(', ')
                                : [];
                            row.push(deltakere);
                            break;
                        }

                        default:
                            field = field.value;
                            row.push(group[field]);
                    }
                }
                table.push(row);
            }

            await this.exportToExcel({
                filename: 'Sorggrupper',
                table: table,
                wscols: wscols,
            });
        },

        /**
         * exportExcelContactInfo
         */
        exportExcelContactInfo: async function () {
            const table = [];
            const header = [];
            const wscols = [];

            for (const field of this.contactFields) {
                header.push(this.fields[field].label);
                wscols.push({ width: this.fields[field].width });
            }

            table.push(header);

            for (const group of this.value) {
                let row = [];

                for (const field of this.contactFields) {
                    row.push(typeof group[field] != undefined ? group[field] : '');
                }

                const subrows = Math.max(Math.max(group.ledere ? group.ledere.length : 0, group.deltakere ? group.deltakere.length : 0), 1);
                for (let r = 0, l = 0, d = 0; r < subrows; r++) {
                    if (group.ledere) {
                        let leder = null;
                        do {
                            leder = group.ledere[l];
                            l++;
                            if (leder && leder.person && !leder.fjernet) {
                                row[3] = leder.person.fornavn + ' ' + leder.person.etternavn;
                                break;
                            }
                        } while (l < group.ledere.length);
                    }

                    if (group.deltakere) {
                        let deltaker = null;
                        do {
                            deltaker = group.deltakere[d];
                            d++;
                            if (deltaker && deltaker.person && !deltaker.fjernet) {
                                row[4] = deltaker.person.fornavn + ' ' + deltaker.person.etternavn;
                                break;
                            }
                        } while (d < group.deltakere.length);
                    }

                    if (r > 0 && !row[3] && !row[4]) {
                        break;
                    }

                    table.push(row);
                    row = [...row];
                    row[3] = '';
                    row[4] = '';
                }
            }

            await this.exportToExcel({
                filename: 'Sorggrupper',
                table: table,
                wscols: wscols,
            });
        },
    },
};
</script>
